// ------------------------
// file: sticky_header.js
// Прикрепляет меню вверх экрана
// ------------------------

(() => {
  const $stickyHeader = $('.js-sticky-header');

  const siteSticky = function($header) {
    const zIndex = parseInt($header.css('z-index'), 10)
    $header.sticky({
      topSpacing: 0,
      zIndex: zIndex,
    });
  };

  siteSticky($stickyHeader);

  const siteScroll = function($header) {
    $(window).scroll(function() {
      const st = $(this).scrollTop();
      if (st > 100) {
        $header
          .removeClass('py-3')
          .addClass('py-0');
      } else {
        $header
          .removeClass('py-0')
          .addClass('py-3');
      }
    })
  }

  siteScroll($stickyHeader);

})();
