// file: my1.owl.carousel.js

// ------------------------
// OWL.CAROUSEL initializer
// ------------------------
(() => {
  $(document).ready(function () {

    // PREV BUTTON
    var prevButton = `
      <span class="cid-owlCarousel-prevButton 
        position-absolute abst-50 absl-5">
        <svg class="btn wem-3em hem-3em">
          <use xlink:href="#039-left-arrow" fill="currentColor"></use>
        </svg>
      </span>
    `;
    // NEXT BUTTON
    var nextButton = `
      <span class="cid-owlCarousel-nextButton 
        position-absolute abst-50 absr-5">
        <svg class="btn wem-3em hem-3em">
          <use xlink:href="#038-right-arrow" fill="currentColor"></use>
        </svg>
      </span>
    `;

    // SAVE BUTTONS IN GLOBAL OBJECT WITH jQuery
    if (!jQuery.ru3gger) {
      const ru3gger = jQuery.ru3gger = {};
      ru3gger.owlCarousel = {};
    } else if (!jQuery.ru3gger.owlCarousel) {
      jQuery.ru3gger.owlCarousel = {};
    }

    const obj = jQuery.ru3gger.owlCarousel;
    obj.prevButton = prevButton;
    obj.nextButton = nextButton;

    // SETTINGS
    $('.owl-carousel').owlCarousel({
      // Buttons
      navText: [obj.prevButton, obj.nextButton],
      // Dots
      dots: true,
      // Responsive
      responsive: {
        0: {
          items: 1,
          nav: false
        },
        768: {
          items: 1,
          nav: false
        },
        992: {
          items: 1,
          nav: true
        }
      }
    });
  });
})();
