/**
 * Создает выезжающую справа страницу
 * с описанием работ по конкретной цене
 */
(() => {
  // Найдем кнопку - триггер
  const btnTrigg = document.querySelectorAll(".cid-pricing-section-aButton");

  // CID выезжающих страниц
  const sectionNamesMap = new Map([
    ["bud", ".cid-more-info-bud-section-wrap"],
    ["std", ".cid-more-info-std-section-wrap"],
    ["top", ".cid-more-info-top-section-wrap"],
  ]);

  // // Найдем выезжающую страницу
  // const outPage = document.querySelector(".cid-more-info-eko-section-wrap");

  // Найдем все выезжающие страницы
  // const outPages = sectionNames.map((name) => document.querySelector(name));

  // Найдем корневой элемент, чтобы спрятать scroll bars
  const rootElm = document.documentElement;

  // Время задержки появления/удаления scroll
  const TIME_FOR_SCROLLBAR_OUT = 500;
  const TIME_FOR_SCROLLBAR_IN = 10;

  // Функция ввода страницы в 'viewport'
  const inViewport = (page) => {
    return () => {
      page.style.transform = "translate3d(0,0,0)";
      page.style.top = "0";
      page.style.bottom = "0";
      page.style.width = "100%";
      // outPage.style.left = "0";
      setTimeout(() => {
        rootElm.style.overflow = "hidden";
      }, TIME_FOR_SCROLLBAR_OUT);
    };
  };

  // Найдем все кнопки 'назад'
  const section = document.querySelector("#pricing-section");
  const btnsBack = section.querySelectorAll("button#btn_back");

  // Функция вывода страницы из 'viewport'
  const outOfViewport = (page) => {
    return () => {
      page.style.transform = "translate3d(100%,0,0)";
      setTimeout(() => {
        rootElm.style.overflow = "auto";
      }, TIME_FOR_SCROLLBAR_IN);
    };
  };

  // Регистрация обработчика 'появления страницы'
  // btnTrigg.addEventListener("click", inViewport);
  btnTrigg.forEach((btn) => {
    const btnID = btn.id;
    if (sectionNamesMap.has(btnID)) {
      const page = document.querySelector(sectionNamesMap.get(btnID));
      btn.addEventListener("click", inViewport(page));
    } else {
      throw new Error("Проблема с выезжающей страницей");
    }
  });

  // Регистрация обработчиков 'удаления страницы'
  btnsBack.forEach((btn) => {
    let curElem = btn;

    while (curElem.nodeName !== "SECTION") {
      curElem = curElem.parentElement;
    }
    btn.addEventListener("click", outOfViewport(curElem));
  });
})();
